import React from 'react'
import { useState, useEffect, useRef } from "react"
import { useHttp } from "../../hooks/http.hook"
import { useMessage } from "../../hooks/message.hook"
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { login, logout } from '../../redux/actions/auth.actions'
import { Loader } from '../../components/Loader'
import { Layout } from '../../components/Layout'

export const UserProfilePage = () => {
  const auth = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const { loading, request, error, clearError, fileUpload } = useHttp()
  const [form, setForm] = useState({
    email: '',
    name: '',
    img: ''
  })
  const message = useMessage()
  const inputFile = useRef(null)
  const history = useNavigate()

  useEffect(() => {
    message(error)
    if (error === 'Нет авторизации') {
      clearError()
      dispatch(logout())
      history('/')
    }
    clearError()
  }, [error, message, clearError, history, dispatch])

  useEffect(() => {
    try {
      if (auth.user) {
        setForm({
          email: auth.user.email,
          name: auth.user.name,
          img: auth.user.photo ? `${auth.user.photo}?v=${Date.now()}` : `/assets/userImg/no.png`
        })
      }
    } catch (e) {
      console.log(e)
    }
  }, [auth])

  const changeHandler = event => {
    setForm({ ...form, [event.target.name]: event.target.value })
  }

  const imgChangeHandler = async event => {
    try {
      const fd = new FormData()
      fd.append('avatar', event.target.files[0])

      const data = await fileUpload('/api/auth/upload', 'POST', fd, { authorization: 'Bearer ' + auth.token, id: auth.user.id })

      setForm({ ...form, img: `/assets/userImg/${auth.user.id}.jpg?v=${Date.now()}` })
      dispatch(login({ token: auth.token, user: { ...auth.user, photo: `/assets/userImg/${auth.user.id}.jpg` } }))

      message(data.message)
    } catch (e) {
      console.log(e)
    }
  }

  const updateHandler = async () => {
    try {
      const user = { ...auth.user, name: form.name, email: form.email }
      dispatch(login({ token: auth.token, user }))
      const data = await request('/api/auth/updateProfile', 'POST', { ...form }, { authorization: 'Bearer ' + auth.token })
      message(data.message)
    } catch (e) { }
  }

  if (!auth.user) {
    return <Loader />
  }
  return (
    <Layout>
      <div className="wrapper">
        <div className="row mb-4">
          <div className="col">
            <h5>Профиль</h5>
            <p className="text-gray">Управляйте своими данными профиля</p>
          </div>
        </div>

        <div className="row flex-column">
          <div className="col-4">
            <p className="mb-3">Изображение профиля</p>
            <img
              style={{
                borderRadius: '50%',
                overflow: 'hidden',
                cursor: 'pointer',
                width: '75px',
                height: '75px',
                border: '1px solid grey'
              }}
              // todo check if exists`/assets/userImg/${props.user.id}.jpg`
              src={form.img}
              alt=""
              onClick={() => {
                inputFile.current.click()
              }}
            />
            <input
              type="file"
              id="avatar"
              ref={inputFile}
              style={{ display: 'none' }}
              // value={form.img}
              onChange={imgChangeHandler}
              accept=".jpg"
            />
          </div>

          <div className="col-4 mt-3">
            <div className="input-group mb-3">
              <input
                placeholder="Email"
                type="text"
                id="email"
                className="form-control"
                name="email"
                value={form.email}
                autoComplete="off"
                onChange={changeHandler}
              />
            </div>

            <div className="input-group mb-3">
              <input
                placeholder="User name"
                type="text"
                id="name"
                className="form-control"
                name="name"
                value={form.name}
                onChange={changeHandler}
              />
            </div>

            <button
              className="btn btn-bg-blue w-100"
              onClick={updateHandler}
              disabled={loading}
            >
              Обновить
            </button>

          </div>

        </div>
      </div>
    </Layout>
  )
}
