import { useEffect, useState } from 'react'
import { Layout } from '../../components/Layout'
import { Bar } from 'react-chartjs-2'
import { Pie } from 'react-chartjs-2'
import React, { useCallback } from 'react'
import { useHttp } from '../../hooks/http.hook'
import { useSelector } from 'react-redux'
import { useMessage } from '../../hooks/message.hook'
import { Loader } from '../../components/Loader'
import { Link } from 'react-router-dom'
import moment from 'moment-timezone/builds/moment-timezone-with-data-2012-2022'


export const Tests = () => {
  const auth = useSelector(state => state.auth)
  const { loading, request, error, clearError } = useHttp()
  const message = useMessage()
  const [testsProgress, setTestProgress] = useState(null)
  const [chartDataTests, setChartDataTests] = useState(null)
  const [chartDataUsers, setChartDataUsers] = useState(null)
  const [jiraConnected, setJiraConnected] = useState(false)

  const testsOptions = {
    offsetGridLines: true,
    drawTicks: true,
    legend: {
      display: true,
      position: 'top',
      align: 'start',
      labels: {
        usePointStyle: true
      }
    },
    scales: {
      yAxes: [
        {
          stacked: true,
          ticks: {
            padding: 5,
            beginAtZero: true
          },
        }
      ],

      xAxes: [
        {
          stacked: true,
          ticks: {
            padding: 0
          },
          gridLines: {
            display: false
          }
        }
      ]
    },
    responsive: true,
    maintainAspectRatio: true,
  }
  const userOptions = {
    legend: {
      display: true,
      position: 'top',
      align: 'start',
      labels: {
        usePointStyle: true
      }
    },
    scales: {
      yAxes: [
        {
          //stacked: true,
          ticks: {
            padding: 5,
            beginAtZero: true
          },
        }
      ],
    },
  }
  const initOptions = {
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          let dataset = data.datasets[tooltipItem.datasetIndex]
          let meta = dataset._meta[Object.keys(dataset._meta)[0]]
          let total = meta.total
          let currentValue = dataset.data[tooltipItem.index]
          let percentage = parseFloat((currentValue / total * 100).toFixed(1))
          return currentValue + ' (' + percentage + '%)'
        },
        title: function (tooltipItem, data) {
          return data.labels[tooltipItem[0].index]
        }
      }
    },
    legend: {
      display: true,
      position: 'top',
      align: 'start',
      labels: {
        usePointStyle: true
      }
    },
  }

  // check requests errors
  useEffect(() => {
    message(error)
    clearError()
  }, [error, message, clearError])

  const updateDataChart = data => {
    let tempTest = {
      labels: [], //dates ADD
      responsive: true,
      offset: true,
      datasets: [
        {
          label: 'PASS',
          backgroundColor: '#82AE34',
          barThickness: 40,
          categoryPercentage: 1,
          data: []
        },
        {
          label: 'FAIL',
          backgroundColor: '#BC401D',
          barThickness: 40,
          categoryPercentage: 1,
          pointStyle: 'triangle',
          data: []
        },
        {
          label: 'WIP',
          backgroundColor: '#E8B33D',
          barThickness: 40,
          categoryPercentage: 1,
          pointStyle: 'triangle',
          data: []
        },
        {
          label: 'BLOCKER',
          backgroundColor: '#7092AD',
          barThickness: 40,
          categoryPercentage: 1,
          pointStyle: 'triangle',
          data: []
        }
      ]
    }

    let tempInit = {
      labels: [],
      datasets:
        [
          {
            label: [],
            data: [],
            backgroundColor: [
              '#82AE34',
              '#BC401D',
              '#E8B33D',
              '#7092AD',
              '#' + Math.floor(Math.random() * 16777215).toString(16),
              '#' + Math.floor(Math.random() * 16777215).toString(16),
              '#' + Math.floor(Math.random() * 16777215).toString(16),
              '#' + Math.floor(Math.random() * 16777215).toString(16),
              '#' + Math.floor(Math.random() * 16777215).toString(16),
              '#' + Math.floor(Math.random() * 16777215).toString(16),
            ],
            borderColor: 'black',
            borderWidth: 0,
          },
        ],
    }

    let tempUser = {
      labels: [''],
      datasets: [],
    }

    let tempObj = {}
    console.log("data.epics.length", data.epics.length)

    try {

      for (let i = 0; i < data.epics.length; i++) {
        console.log("RRRRRRRRRR")
        if (data.epics[i].tasks.length !== 0) {
          tempInit.datasets[0].data.push(data.epics[i].tasks.length)
          tempInit.labels.push(data.epics[i].summary)
          for (let j = 0; j < data.epics[i].tasks.length; j++) {

            tempInit.datasets[0].label.push(data.epics[i].tasks[j].parent.fields.summary)
            if (data.epics[i].tasks[j].status.name === 'Готово') {

              // try {


              console.log("______")


              console.log(tempObj)
              console.log(data.epics[i].tasks[j].fields?.displayName)


              console.log("______")

              if (tempObj.hasOwnProperty(data.epics[i].tasks[j].creator?.displayName)) {


                console.log("NNNNNNNNNNN")

                tempObj[data.epics[i].tasks[j].creator?.displayName] = tempObj[data?.epics[i]?.tasks[j]?.creator?.displayName] + 1 // количество тестов по пользователям
              } else {
                tempObj[data.epics[i].tasks[j].creator?.displayName] = 1
              }
              // } catch (e) {

              //   console.log("error", e)

              //  }
            }

            tempTest.labels.push(data.epics[i].tasks[j].resolutiondate?.substr(0, 10))
          }
        }
      }


    } catch (e) {

      console.log("error", e)

    }


    console.log("YYYYYYYYYYYYYYY", tempTest)

    tempTest.labels = Array.from(new Set(tempTest.labels))
    tempTest.labels = tempTest.labels.sort((a, b) => moment(a, "YYYY-MM-DD") - moment(b, "YYYY-MM-DD"))

    tempTest.labels.forEach(el => {
      tempTest.datasets[0].data.push(0)
      tempTest.datasets[1].data.push(0)
      tempTest.datasets[2].data.push(0)
      tempTest.datasets[3].data.push(0)
    })

    for (let i = 0; i < data.epics.length; i++) {
      for (let j = 0; j < data.epics[i].tasks.length; j++) {
        try {

          if (data.epics[i].tasks[j].labels[0] === 'PASS') {
            tempTest.datasets[0].data[tempTest.labels.indexOf(data.epics[i].tasks[j].resolutiondate.substr(0, 10))] = tempTest.datasets[0].data[tempTest.labels.indexOf(data.epics[i].tasks[j].resolutiondate.substr(0, 10))] + 1
          }
          if (data.epics[i].tasks[j].labels[0] === 'FAIL') {
            tempTest.datasets[1].data[tempTest.labels.indexOf(data.epics[i].tasks[j].resolutiondate.substr(0, 10))] = tempTest.datasets[1].data[tempTest.labels.indexOf(data.epics[i].tasks[j].resolutiondate.substr(0, 10))] + 1
          }
          if (data.epics[i].tasks[j].labels[0] === 'WIP') {
            tempTest.datasets[2].data[tempTest.labels.indexOf(data.epics[i].tasks[j].resolutiondate.substr(0, 10))] = tempTest.datasets[2].data[tempTest.labels.indexOf(data.epics[i].tasks[j].resolutiondate.substr(0, 10))] + 1
          }
          if (data.epics[i].tasks[j].labels[0] === 'BLOCKER') {
            tempTest.datasets[3].data[tempTest.labels.indexOf(data.epics[i].tasks[j].resolutiondate.substr(0, 10))] = tempTest.datasets[3].data[tempTest.labels.indexOf(data.epics[i].tasks[j].resolutiondate.substr(0, 10))] + 1
          }

        } catch (e) {

          console.log("][[][[][][[][][]---------", e)
        }

      }
    }


    console.log("tempObj--------------------", tempObj)

    for (let key in tempObj) {
      tempUser.datasets.push(
        {
          label: [key],
          data: [tempObj[key]],
          backgroundColor: [
            '#' + Math.floor(Math.random() * 16777215).toString(16),
          ],
        },
      )
    }

    setTestProgress(tempTest)
    setChartDataTests(tempInit)
    setChartDataUsers(tempUser)
  }

  // get project issues
  const getProjectIssues = useCallback(async (token, form) => {
    try {
      const data = await request(`/api/jira/projectIssues`, 'POST', { ...form }, { authorization: 'Bearer ' + token })
      console.log("data - ", data)
      updateDataChart(data)

      message(data.message)
    } catch (e) {
      console.log(e)
    }
  }, [request, message])

  // Jira project integration completed
  useEffect(() => {
    let jiraConn = auth.user.jiraUrl && auth.user.jiraName && auth.user.jiraPass && auth.user.jiraProject
    if (jiraConn) {
      setJiraConnected(true);
      (async () => {
        await getProjectIssues(auth.token, {
          jiraUrl: auth.user.jiraUrl,
          jiraName: auth.user.jiraName,
          jiraPass: auth.user.jiraPass,
          jiraProject: auth.user.jiraProject,
        })
      })()
    }
  }, [auth.user])

  if (loading) {
    return (<Loader />)
  }



  console.log("testsProgress - ", testsProgress)
  console.log("chartDataTests - ", chartDataTests)
  console.log("chartDataUsers - ", chartDataUsers)


  return (
    <Layout isTest={true}>
      <div className="container">
        <div className="wrapper">
          <p className="m-0 text-2xl f-medium dark">Прогресс по тестированию</p>
          <p className="text-xs coolGray400 mb-5">Список записанных тест кейсов проекта</p>

          {jiraConnected ?
            <>
              <div className="barMain">
                <h4 className="text-lg">Ежедневные прогресс по тестированию</h4>
                {testsProgress && <Bar height={70} data={testsProgress} options={testsOptions} />}
              </div>

              <div className="chartsInline">
                <div className="pie">
                  <h4 className="text-lg">Запуски тестов по циклам</h4>
                  {chartDataTests && <Pie height={150} data={chartDataTests} options={initOptions} />}
                </div>

                <div className="bar">
                  <h4 className="text-lg">Запуски тестов по пользователям</h4>
                  {chartDataUsers && <Bar height={165} data={chartDataUsers} options={userOptions} />}
                </div>
              </div>
            </>
            :
            <>
              <p className="text-xl-center">Пока у вас не подключен проект <Link to={`/test/integration`}>Jira</Link></p>
            </>
          }

        </div>
      </div>
    </Layout>
  )
}
