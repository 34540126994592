import { Layout } from '../../components/Layout'
import CodeMirror from '@uiw/react-codemirror'
import { linter, openLintPanel } from '@codemirror/lint'
import { javascript, esLint } from '@codemirror/lang-javascript'
import { php, phpLanguage } from '@codemirror/lang-php'
import { cpp, cppLanguage } from '@codemirror/lang-cpp'
import { python, pythonLanguage } from '@codemirror/lang-python'
import Linter from 'eslint4b-prebuilt'
import { useEffect, useRef, useState } from 'react'
import { markdown, markdownLanguage } from '@codemirror/lang-markdown'
import { TreeView } from '../../components/TreeView'
import { useSelector } from 'react-redux'
import { useHttp } from '../../hooks/http.hook'
import { Problem } from '../../components/Problem'


/**
 *  to use jshint (nodeJS polifills)
 *
 *  npm install react-app-rewired --save // allow modify webpack config
 *  npm install node-polyfill-webpack-plugin --save
 *
 *  in root create config-overrides.js and write inside this
 *
 *      module.exports = function override(config, env) {
 *        config.plugins.push(new NodePolyfillPlugin({
 *          excludeAliases: ['console']
 *        }))
 *        return config
 *      }
 *
 * @returns {JSX.Element}
 * @constructor
 */

// let lang = {
//   js: [[javascript({ jsx: true }), linter(esLint(new Linter()))], "snippet.js"],
//   php: [[php(), linter(phpcs())], "snippet.php"], // Используем phpcs для PHP
//   cpp: [[cpp(), cppLanguage], "snippet.cpp"],
//   py: [[python(), linter(pylint())], "snippet.py"], // Используем pylint для Python
//   md: [[markdown({ base: markdownLanguage })], "snippet.md"],
// }










// Функции для настройки линтеров
function phpcs() {
  return {
    async check(view) {
      const code = view.state.doc.toString()
      // Логика для запуска phpcs
      return []
    }
  }
}

function pylint() {
  return {
    async check(view) {
      const code = view.state.doc.toString()
      // Логика для запуска pylint
      return []
    }
  }
}







export const Code = () => {


  let lang = {
    js: [[javascript({ jsx: true }), linter(esLint(new Linter()))], "snippet.js"],
    php: [[php(), linter(phpcs())], "snippet.php"],
    cpp: [[cpp(), cppLanguage], "snippet.cpp"],
    py: [[python(), linter(pyLintWrapper().check)], "snippet.py"],
    md: [[markdown({ base: markdownLanguage })], "snippet.md"],
  }


  const auth = useSelector(state => state.auth)
  const { loading, request, error, clearError } = useHttp()
  const codeRef = useRef(null)
  const view = useRef(null)
  const [theme, setTheme] = useState('dark')
  const [extensions, setExtensions] = useState(lang.js[0])
  const [language, setLanguage] = useState(lang.js[1])
  const [editingFile, setEditingFile] = useState(null)
  const [value, setValue] = useState('print("Hello, Python!")\nconsole.log("Hello, JavaScript!")\n<?php echo "Hello, World!";?>\nstd::cout << "Hello, C++!";\n_**`Hello, Markdown`**_')
  const [err, setErr] = useState(false)

  const [showTerminal, setShowTerminal] = useState(false)

  const [pytonLint, setPytonLint] = useState('')

  const [fileName, setFileName] = useState('')

  const changeHandler = (e) => {
    setExtensions(lang[e.target.value][0])
    setLanguage(lang[e.target.value][1])
    if (e.target.value !== 'py') {
      setPytonLint('')
    }
  }

  // show linter panel
  // useEffect(() => {
  //   if (view && view.current && view.current.view) openLintPanel(view.current.view)
  // }, [])

  const downloadTxtFile = (code) => {
    const element = document.createElement("a")
    const file = new Blob([code], { type: 'text/plain' })
    element.href = URL.createObjectURL(file)
    element.download = language
    document.body.appendChild(element) // Required for this to work in FireFox
    element.click()
  }


  const aiCodeCheck = async () => {
    try {
      const data = await request('/api/ai/aicodecheck', 'POST', { text: value }, { authorization: 'Bearer ' + auth.token })
      setValue(data.gemini.text)
    } catch (e) {
      console.log(e)
    }
  }


  function pyLintWrapper() {
    return {
      async check(view) {
        console.log("pyLintWrapper check called")
        const code = view.state.doc.toString()
        console.log("Code to lint:", code)

        try {
          const response = await request('/api/filemanager/pylint', 'POST', { code }, { authorization: 'Bearer ' + auth.token })

          console.log("Linting response:", response)
          setPytonLint(response.stdout)
          return []
        } catch (error) {
          console.error('Error linting Python code:', error)
          return [] // Возвращаем пустой массив в случае ошибки
        }
      }
    }
  }


  const getFileContent = async (path) => {
    try {
      const data = await request('/api/filemanager/getContent', 'POST', { path }, { authorization: 'Bearer ' + auth.token })
      setValue(data.content)
    } catch (e) {
      setValue('')
      setErr(true)
      console.log(e)
    }
  }

  const saveFileContent = async (path) => {
    if (!err) {
      try {
        const data = await request('/api/filemanager/saveContent', 'POST', { path, content: value }, { authorization: 'Bearer ' + auth.token })
      } catch (e) {
        console.log(e)
      }
    } else {
      setErr(false)
    }
  }

  const getFileName = (path) => {
    const parts = path.split('\\')
    setFileName(parts[parts.length - 1])
  }

  const fileClicked = async (node) => {
    if (node && node.path) {
      if (editingFile && editingFile.path) {
        await saveFileContent(editingFile.path, value)
      }
      setEditingFile(node)
      await getFileContent(node.path)
      getFileName(node.path)
    }
  }


  const code = `
functi2on helloWorld() {
  console.log("Hello, world!")
}
`

  return (
    <Layout sidebar={false}>
      <div className="container">
        <div className="wrapper">

          <div className="filemanager">
            <TreeView fileClicked={fileClicked} theme={theme} />
            <div style={{ width: '100%' }}>
              <CodeMirror
                ref={view}
                value={value}

                height={showTerminal ? '500px' : '700px'}
                extensions={extensions}
                theme={theme}
                onChange={(value, viewUpdate) => {
                  // codeRef.current = value
                  setValue(value)
                  // console.log('value:', value)
                  // console.log('viewUpdate:', viewUpdate)
                }}
              />

              <Problem pytonLint={pytonLint} codeForCheck={value} languageName={'JavaScript'} fileName={fileName} theme={theme} showTerminal={showTerminal} setShowTerminal={setShowTerminal} />

            </div>


          </div>

          <div className="container">
            <div className="row">
              <div className="col">
                <button className="btn btn-bg-blue minWidth-136 text-base mt-4 me-2" onClick={() => {
                  // console.log(codeRef.current)
                  downloadTxtFile(codeRef.current)
                }}>Сохранить</button>

                <button className="btn btn-bg-blue minWidth-136 text-base mt-4 me-2" onClick={() => {
                  // openLintPanel(view.current.view)
                  setShowTerminal(true)
                }}>Показать ошибки</button>



                <button className="btn btn-bg-blue minWidth-136 text-base mt-4 me-2" onClick={() => {
                aiCodeCheck()
                }}>Исправить</button>



                <button className="btn btn-bg-blue minWidth-136 text-base mt-4 me-2" onClick={() => {
                  theme === 'dark' ? setTheme('light') : setTheme('dark')
                }}>Сменить тему</button>
              </div>
              <div className="col-2">
                <select
                  name="Lang"
                  className="form-select form-control minWidth-136 text-base mt-4 me-2"
                  onChange={changeHandler}
                >
                  <option value={'js'}>JavaScript</option>
                  <option value={'php'}>PHP</option>
                  <option value={'cpp'}>C++</option>
                  <option value={'py'}>Python</option>
                  <option value={'md'}>Markdown</option>
                </select>
              </div>
            </div>
          </div>


        </div>
      </div>
    </Layout>
  )
}