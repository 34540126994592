import React, { useEffect, useState } from 'react'
import { JSHINT as jshint } from 'jshint'

export const Problem = ({ codeForCheck, languageName, fileName, theme, showTerminal, setShowTerminal, pytonLint }) => {
  const [problems, setProblems] = useState([])

  useEffect(() => {
    checkMistakes(codeForCheck)
  }, [codeForCheck])

  const checkErrors = value => {
    jshint(value.split('\n'))
    return jshint.data().errors ? jshint.data().errors : []
  }

  const checkMistakes = (code) => {
    if (!code) {
      return
    }
    checkErrors(code)
    setProblems(jshint?.data()?.errors)
    console.warn(jshint.data())
    console.warn(jshint.data().errors)
  }






  const Warning = () => {
    return (

      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.75 2.25V7.5H15L8.25 15.75V10.5H3L9.75 2.25Z" stroke="url(#paint0_linear_1_30176)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <defs>
          <linearGradient id="paint0_linear_1_30176" x1="3" y1="6.46875" x2="15.4408" y2="8.03919" gradientUnits="userSpaceOnUse">
            <stop stop-color="#F4C78C" />
            <stop offset="1" stop-color="#F9DF8F" />
          </linearGradient>
        </defs>
      </svg>

    )
  }



  const Error = () => {
    return (

      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.5 10.5L9 9M9 9L10.5 7.5M9 9L7.5 7.5M9 9L10.5 10.5M15.75 9C15.75 9.88642 15.5754 10.7642 15.2362 11.5831C14.897 12.4021 14.3998 13.1462 13.773 13.773C13.1462 14.3998 12.4021 14.897 11.5831 15.2362C10.7642 15.5754 9.88642 15.75 9 15.75C8.11358 15.75 7.23583 15.5754 6.41689 15.2362C5.59794 14.897 4.85382 14.3998 4.22703 13.773C3.60023 13.1462 3.10303 12.4021 2.76381 11.5831C2.42459 10.7642 2.25 9.88642 2.25 9C2.25 7.20979 2.96116 5.4929 4.22703 4.22703C5.4929 2.96116 7.20979 2.25 9 2.25C10.7902 2.25 12.5071 2.96116 13.773 4.22703C15.0388 5.4929 15.75 7.20979 15.75 9Z" stroke="url(#paint0_linear_1_30177)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <defs>
          <linearGradient id="paint0_linear_1_30177" x1="2.25" y1="5.625" x2="15.8653" y2="5.86621" gradientUnits="userSpaceOnUse">
            <stop stop-color="#EC847D" />
            <stop offset="1" stop-color="#F2AF96" />
          </linearGradient>
        </defs>
      </svg>

    )
  }



  const File = ({ color }) => {
    return (


      <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19 9V17.8C19 18.9201 19 19.4802 18.782 19.908C18.5903 20.2843 18.2843 20.5903 17.908 20.782C17.4802 21 16.9201 21 15.8 21H8.2C7.07989 21 6.51984 21 6.09202 20.782C5.71569 20.5903 5.40973 20.2843 5.21799 19.908C5 19.4802 5 18.9201 5 17.8V6.2C5 5.07989 5 4.51984 5.21799 4.09202C5.40973 3.71569 5.71569 3.40973 6.09202 3.21799C6.51984 3 7.0799 3 8.2 3H13M19 9L13 3M19 9H14C13.4477 9 13 8.55228 13 8V3" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>

    )

  }


  const listOfProblems = problems?.map((problem, idx) =>

    <li key={idx}>
      <div style={{ display: 'flex', alignItems: 'center', padding: '0.1rem 0.7rem', gap: '5px' }}>
        {problem.code !== 'E019' ? <Warning /> : <Error />}
        <div style={{ color: theme === 'dark' ? '#9CA3AF' : '#000' }}> {problem.reason}   Line: {problem.line} Character: {problem.character}</div>
      </div>
    </li>
  )


  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    height: '200px',
    maxHeight: '200px',
    width: '100%',
    borderTop: '1px solid',
    borderTopColor: theme === 'dark' ? '#30363D' : '#E6E6E9',
    backgroundColor: theme === 'dark' ? '#282c34' : '#F8FAFC'
  }

  const headerStyle = {
    borderBottom: `1px solid`,
    borderBottomColor: theme === 'dark' ? '#30363D' : '#E6E6E9',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: theme === 'dark' ? '#282c34' : '#E6E6E9'
  }

  const buttonStyleZERO = {
    // borderLeft: '1px solid #30363D',
    // borderRight: '1px solid #30363D',
    padding: '0.2rem 1.1rem',
    color: theme === 'dark' ? '#9CA3AF' : '#000',
    backgroundColor: theme === 'dark' ? '#282c34' : '#F5F5F5'
  }

  const buttonStyleCLICK = {
    padding: '0.2rem 1.1rem',
    color: theme === 'dark' ? '#9CA3AF' : '#000',
    backgroundColor: theme === 'dark' ? '#282c34' : '#F5F5F5',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease' // Плавный переход фона
  }

  const buttonHoverStyle = {
    backgroundColor: theme === 'dark' ? '#3a3f47' : '#e0e0e0' // Цвет фона при наведении
  }
  const fileStyle = {
    display: 'flex',
    alignItems: 'center',
    padding: '0.1rem 0.7rem',
    gap: '5px'
  }

  const fileNameStyle = {
    color: theme === 'dark' ? '#fff' : '#000'
  }


  return (
    <>
      {
        showTerminal === true
          ?
          <div>
            <div style={containerStyle}>
              <div style={headerStyle}>
                <div style={buttonStyleZERO}>Проблемы</div>


                <div
                  style={buttonStyleCLICK}
                  onClick={() => setShowTerminal(false)}
                  onMouseEnter={(e) => e.currentTarget.style.backgroundColor = buttonHoverStyle.backgroundColor}
                  onMouseLeave={(e) => e.currentTarget.style.backgroundColor = buttonStyleCLICK.backgroundColor}
                >
                  Свернуть
                </div>


              </div>

              <div style={fileStyle}>
                <File color={theme === 'dark' ? '#9CA3AF' : '#000'} />
                <div style={fileNameStyle}>{fileName}</div>
              </div>

              {languageName === "JavaScript" ?
                <div style={{ height: '100%', overflow: 'auto' }}>

                  {
                    !pytonLint
                      ?
                      <ul>{listOfProblems}</ul>
                      :
                      <ul>
                        <li>
                          <div style={{ display: 'flex', alignItems: 'center',  padding: '0.1rem 0.7rem', gap: '5px' }}>
                            <div style={{ width: '20px', height: '20px' }}><Warning /></div>
                            <div style={{ color: theme === 'dark' ? '#9CA3AF' : '#000' }}> {pytonLint} </div>
                          </div>
                        </li>
                      </ul>
                  }



                </div>
                : null
              }
            </div>
          </div>
          :

          <></>

      }

    </>
  )
}

