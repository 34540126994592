import { Layout } from '../../components/Layout'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { updateForm } from '../../redux/actions/arch.actions'

export const Arch = () => {
  const [appType, setAppType] = useState('Web')
  const history = useNavigate()
  const dispatch = useDispatch()
  const nextPage = '/arch/blocks'

  const changeHandler = (e) => {
    setAppType(e.target.value)
  }

  const btnClick = () => {
    dispatch(updateForm({appType}))
    history(nextPage, { state: appType })
  }

  const goNext = e => {
    if (e.key === 'Enter') {
      btnClick()
    }
    // e.key === 'Enter' ? console.log('Enter click') : console.log(e.key)
  }

  // On "Enter" pressed anywhere
  useEffect(() => {
    document.addEventListener('keydown', goNext)

    // remove listener on unmount component
    return () => {
      document.removeEventListener('keydown', goNext)
    }
  }, [appType])


  return (
    <Layout sidebar={false} footer={true} footerPerc={37} footerText="Архитектура подготовлена на 37%">
      <div className="container">
        <div className="w-100 d-flex justify-content-center align-items-center center_wrapper">
          <div className="d-flex flex-column align-items-start">
            <span className="text-2xl">Выберите тип приложения</span>
            <span className="text-base mb-4">выберите тип приложения, который вы хотите создать</span>

            <div className="input-group mb-4">
              <select
                name="appType"
                className="form-select form-control"
                onChange={changeHandler}
              >
                <option value="Web">Web</option>
                <option value="Desktop">Desktop</option>
                <option value="Micro">Micro Service</option>
              </select>
            </div>

            {/*button+text*/}
            <div className="d-flex justify-content-start align-items-center">
              <button className="btn btn-bg-blue minWidth-136 text-base" onClick={btnClick}>Найти</button>
              <span className="text-sm blueGray400 ms-3">или нажмите <span className="f-bold">ENTER</span></span>
            </div>

          </div>
        </div>
      </div>
    </Layout>
  )
}