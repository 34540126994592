import { Layout } from '../../components/Layout'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Icon } from '../../components/Icon'

export const Cases = () => {
  const auth = useSelector(state => state.auth)

  const [cases, setCases] = useState([
    {id:1,projectId:1,caseId:0,userId:1,browser:'Firefox',os:'Windows',server:'',data:'',description:'Test',createdAt:'2021-06-12T22:49:47.000Z',updatedAt:'2021-06-12T22:49:47.000Z'},
    {id:2,projectId:1,caseId:0,userId:1,browser:'Chrome',os:'MacOs',server:'',data:'',description:'',createdAt:'2021-06-12T22:50:19.000Z',updatedAt:'2021-06-12T23:13:14.000Z'}
  ])

  // todo fetch cases from foreign DB

  const deleteHandler = id => {
    try {
      let rc = cases.filter(el => el.id !== id)
      setCases([...rc])
    } catch (e) {}
  }

  return (
    <Layout isTest={true}>
      <div className="container">
        <div className="wrapper">
          <p className="m-0 text-2xl f-medium dark">Тест кейсы</p>
          <p className="text-xs coolGray400 mb-4">Список записанных тест кейсов проекта</p>

          {cases && cases.length ?
            <>
              <table className="table table-hover ">
                <thead>
                <tr>
                  <th className="txt-gray text-xs">Название</th>
                  <th className="txt-gray text-xs">Пользователь</th>
                  <th />
                  <th className="txt-gray text-xs">Проект</th>
                  <th className="txt-gray text-xs">Дата создания</th>
                  <th/>
                </tr>
                </thead>
                <tbody>
                {cases && cases.length ? cases.map(c => {
                  return(
                    <React.Fragment key={c.id}>
                      <tr
                        key={c.id}
                        className="text-xs table-text-color"
                      >
                        <td valign="middle" className="text-left">
                          <i style={{cursor: 'pointer'}} className="fa fa-play-circle-o txt-gray" aria-hidden="true" onClick={() => {}}/>
                          {c.description || '-'}
                        </td>
                        <td valign="middle" className="text-left">{auth && auth.user ? auth.user.email : '-'}</td>

                        <td className="">
                          {c.os === 'Windows' ? (<Icon name="windows"/>) : (c.os === 'MacOs' ? (<Icon name="apple"/>) : (<Icon name="mobile"/>))}
                          {c.browser === 'Firefox' ? (<Icon name="firefox"/>) : (c.browser === 'Opera' ? (<Icon name="opera"/>) : (c.browser === 'Chrome' ? (<Icon name="chrome"/>) : (<Icon name="ie"/>)))}
                        </td>

                        <td valign="middle" className="">-</td>
                        <td valign="middle" className="text-left td-cOn">{new Date(c.createdAt).toLocaleDateString('Ru-ru')}</td>
                        <td valign="middle" className="text-left td-actions">
                          <i
                            style={{cursor: 'pointer'}}
                            className="fa fa-trash-o pl-1 opacity-0"
                            aria-hidden="true"
                            onClick={() => {deleteHandler(c.id)}}
                          />
                        </td>
                      </tr>
                    </React.Fragment>
                  )
                }) : (<></>)}
                </tbody>
              </table>
            </>
            :
            <p className="text-xl-center">Пока у вас нет ни одного запущенного теста</p>
          }
        </div>
      </div>
    </Layout>
  )
}