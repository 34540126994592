import { useEffect, useRef, useState } from 'react'
import { Layout } from '../../components/Layout'
// import { useSpellCheck } from '../../hooks/spellCheck.hook'
import { Loader } from '../../components/Loader'
import { PopupEl } from '../../components/PopupEl'
import { useCheckWords } from '../../hooks/checkWords.hook'
//import { WordSpan } from '../../components/WordSpan'
import { useHttp } from '../../hooks/http.hook'
import { useSelector } from 'react-redux'



export const Docs = () => {
  // const spellcheck = useSpellCheck()
  const { loading, request, error, clearError } = useHttp()
  const { working, checkIt, legend } = useCheckWords()
  const textBox = useRef(null)
  const [show, setShow] = useState(false)
  const auth = useSelector(state => state.auth)


  const showPopup = e => {
    if (e.key === '/') {
      e.preventDefault()
      e.stopPropagation()
      setShow(prev => !prev)
    }
  }

  // On "/" pressed anywhere
  useEffect(() => {
    document.addEventListener('keydown', showPopup)

    // remove listener on unmount component
    return () => {
      document.removeEventListener('keydown', showPopup)
    }
  }, [])

  const downloadTxtFile = (doc) => {
    console.log(doc)
    const element = document.createElement("a")
    const file = new Blob([doc], { type: 'text/plain' })
    element.href = URL.createObjectURL(file)
    element.download = "documentation.txt"
    document.body.appendChild(element) // Required for this to work in FireFox
    element.click()
  }

  const handleFile = (e) => {
    const content = e.target.result
    textBox.current.innerHTML = content
  }

  const handleChangeFile = (file) => {
    let fileData = new FileReader()
    fileData.onloadend = handleFile
    fileData.readAsText(file)
  }




  



  const aiSpellText = async (text) => {
    try {
      const data = await request('/api/ai/aispelltext', 'POST', { text }, { authorization: 'Bearer ' + auth.token })

      textBox.current.innerHTML = data.gemini.text

    
    } catch (e) {
    
      console.log(e)
    }
  }





  return (
    <Layout sidebar={false}>
      <div className="container">
        <div className="wrapper">
          <p className="p-0 m-0"><span className="text-xl f-medium">Анализ требований на полноту и корректность</span></p>
          <span className="text-sm blueGray400">Ввидете ‘/’ чтобы вставить готовый шаблон документа</span>

          <div className="d-flex mt-3 w-100">
            <div className="editContainer">
              {/*popup window*/}
              <div className={`popup position-absolute ${!show ? 'd-none' : 'd-flex flex-column'}`}>
                <PopupEl onClick={() => {
                  textBox.current.innerHTML = `
Это руководство предназначено для обучения операторов работы с программным обеспечением. Оно охватывает основные функции и процессы, необходимые для эффективного использования системы, включая настройку оборудования, запуск и управление приложением, а также выполнение регулярных операций и устранение распространенных проблем. В руководстве содержатся пошаговые инструкции, которые помогут оператору освоить систему и обеспечить её стабильную работу.`
                  setShow(false)
                }} text={'Руководство оператора'} />
                <PopupEl onClick={() => {
                  textBox.current.innerHTML = `В этом разделе представлено подробное описание алгоритма, используемого в системе. Алгоритм начинается с общего обзора его цели и области применения, а затем переходит к описанию его ключевых этапов и логики работы. Псевдокод и диаграммы, приведенные в разделе, иллюстрируют последовательность операций и внутренние механизмы работы алгоритма. Кроме того, обсуждаются особенности реализации алгоритма на выбранном языке программирования и возможные вариации для оптимизации производительности и повышения надежности.
`
                  setShow(false)
                }} text={'Описание алгоритма'} />
                <PopupEl onClick={() => {
                  textBox.current.innerHTML = `Данное руководство предназначено для программистов, работающих с нашей системой. В нем представлены основные принципы архитектуры и структуры кода, используемые в проекте, а также рекомендации по разработке и поддержке программного обеспечения. В руководстве описаны ключевые компоненты системы, принципы их взаимодействия и общие стандарты кодирования. Также приведены примеры кода и лучшие практики для эффективного решения задач и обеспечения качества программного продукта.`
                  setShow(false)
                }} text={'Руководство программиста'} />

              </div>

              {/*spellCheck подчёркивает все английские слова*/}
              {working ?
                <Loader />
                :
                <div id='txtar' ref={textBox} className="textEdit" contentEditable={true} spellCheck={false}
                />
              }

              <div className="container">
                <div className="row">

                  <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>


                    <button className="btn btn-bg-blue minWidth-136 text-base mt-4 me-2" onClick={() => {
                      checkIt(textBox.current.innerText).then(res => { textBox.current.innerHTML = res }).catch(e => { console.log('error', e) })
                    }}>Проверить</button>

                    <button className="btn btn-bg-blue minWidth-136 text-base mt-4 me-2" onClick={() => {
                      aiSpellText(textBox.current.innerText)
                    }}>Исправить</button>

                    <button className="btn btn-bg-blue minWidth-136 text-base mt-4 me-2" onClick={() => {
                      downloadTxtFile(textBox.current.innerText)
                    }}>Скачать</button>

                    <input className="form-control minWidth-116 text-base mt-4 me-2" type={"file"} onChange={e => handleChangeFile(e.target.files[0])}></input>
                  </div>
             
                </div>
              </div>

              {/*<button className="btn btn-bg-blue minWidth-136 text-base mt-4 me-2" onClick={() => {*/}
              {/*  spellcheck.check(textBox.current.innerHTML)*/}
              {/*    .then(res => {*/}
              {/*      console.log(res)*/}
              {/*      textBox.current.innerHTML = res*/}
              {/*    })*/}
              {/*}}>Test</button>*/}
            </div>

            <div className="legend h-100 d-flex flex-column justify-content-center align-items-center">
              <div id='issues'>

                {/* <p id='word'></p> */}
                <p id='category'></p>
                <p id='advice'></p>
                <span className="text-sm blueGray400">Разрешить слово: Alt+Shift+click по слову</span>
              </div>

              {/* {Object.keys(legend).map(cat => {
                  return (
                    <div className="d-flex align-items-center gap-2" key={cat}>
                      <span className="legendTag" style={{ backgroundColor: legend[cat].color }} />
                      <span className="text-base">{legend[cat].title}</span>
                    </div>
                  )
                })} */}
            </div>
          </div>

        </div>
      </div>
    </Layout>
  )
}